import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { LandingPage } from './pages/LandingPage';
import { ContactUs } from './pages/ContactUs';

function App() {
  return (
      <ChakraProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </Router>
      </ChakraProvider>
  );
}

export default App;